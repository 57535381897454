<template>
	<div class="o-row o-row--sm">
		<div class="o-container o-container--lg">
			<Breadcrumbs :name="machineName" />
			<div v-if="machine && machine.location" class="c-machine">
				<div class="grid">
					<div class="row">
						<div class="col-lg-6">
							<div class="c-machine__info">
								<div v-if="machine.out_of_use" class="c-machine__unavailable">
									<span class="c-machine__unavailable--title">Deze automaat is buiten gebruik</span>
									<button class="c-machine__unavailable--button" @click="changeOutOfUse(true)">
										<span>Aanpassen</span>
									</button>
								</div>
								<div class="grid">
									<div class="row">
										<div class="col-lg-4">
											<img
												class="c-machine__thumbnail"
												:src="
													machine.imgPath !== undefined
														? machine.imgPath
														: require('@/assets/images/vending_placeholder.png')
												"
												alt="machine"
											/>
										</div>

										<div class="col-lg-8">
											<div class="u-flex u-jc-between">
												<div>
													<p class="c-machine__address">
														{{ machine.address.street }}, {{ machine.address.number }},
														{{ machine.address.city }}
													</p>
													<p class="c-machine__name">
														{{ machine.name }}
													</p>
												</div>
												<div>
													<span v-if="machine.distance" class="c-machine__distance">
														{{ machine.distance }} Km away</span
													>
												</div>
											</div>

											<div class="c-machine__tags">
												<Tag v-for="tag in categories" :key="tag.category_id" :tag="tag" />
											</div>
											<div class="c-machine__hours">
												<img src="@/assets/images/icons/clock.svg" alt="opening hours" />
												<span class="u-tt-c">{{
													translateOpeningTime(machine.opening_time.name)
												}}</span>
											</div>
											<div class="c-machine__hours">
												<img src="@/assets/images/icons/house.svg" alt="opening hours" />
												<span class="u-tt-c">{{
													translateLocation(machine.location.name)
												}}</span>
											</div>
											<div v-if="machine.is_premium" class="c-machine__hours">
												<img src="@/assets/images/icons/website.svg" alt="opening hours" />
											</div>
											<a v-if="machine.is_premium" :href="machine.website">{{
												machine.website
											}}</a>
										</div>
									</div>
								</div>
								<Tabs class="c-tabs" />
								<div class="c-actions">
									<button class="c-actions__edit" @click="openEditModal()">
										<span>Bewerking voorstellen</span>
									</button>
									<Share />
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="c-overview__map">
								<DetailMap />
								<Share />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<ConfirmationModal
		v-show="showConfirmationModal"
		:show-confirmation-modal="showConfirmationModal"
		:confirmation-title="confirmationOutOfUse.title"
		:confirmation-description="confirmationOutOfUse.description"
		@closeConfirmationModal="closeConfirmationModal()"
		@confirmAction="outOfUse(confirmationOutOfUse.value, machine.vending_machine_id)"
	/>
	<EditModal
		v-show="showEditModal"
		:show-edit-modal="showEditModal"
		@closeModal="closeEditModal()"
		@openConfirmationModal="changeOutOfUse(false)"
	/>
</template>

<script src="./Detail.controller.js"></script>
<style lang="scss" src="./Detail.styles.scss"></style>
