import { mapActions, mapGetters } from "vuex";

import { Breadcrumbs, DetailMap, Tag, Tabs, ConfirmationModal, EditModal, Share } from "@/components";
import { translateLocation, translateOpeningTime } from "@/utils";
import _ from "lodash";

export default {
	name: "Detail",
	props: {
		Tag,
	},
	components: {
		Breadcrumbs,
		DetailMap,
		Tag,
		Tabs,
		ConfirmationModal,
		EditModal,
		Share,
	},
	data: () => ({
		confirmationOutOfUseTrue: {
			title: "Bevestig buiten gebruik",
			description:
				"Bevestig dat deze automaat buiten gebruik is. Als deze automaat verbonden is aan zijn eigenaar zal deze verwittigd worden.",
			value: true,
		},
		confirmationOutOfUseFalse: {
			title: "Bevestig terug in gebruik",
			description:
				"Bevestig dat deze automaat niet meer buiten gebruik is. Als deze automaat verbonden is aan zijn eigenaar zal deze verwittigd worden.",
			value: false,
		},
		outOfUseValue: true,
		showConfirmationModal: false,
		showEditModal: false,
	}),
	computed: {
		...mapGetters("machine", {
			machine: "getMachine",
		}),
		machineName() {
			if (this.machine && this.machine.address) {
				return this.machine.name
					? this.machine.name
					: `${this.machine.address.street} ${this.machine.address.number}, ${this.machine.address.city}`;
			}

			return "";
		},
		tags() {
			// Todo return tags from api
			return [
				{
					id: 1,
					name: "Aardbeien",
					backgroundColor: "#F6CACA",
					color: "#DF3D3C",
				},
				{
					id: 2,
					name: "Verse maaltijden",
					backgroundColor: "#DEF5D1",
					color: "#7DD747",
				},
			];
		},
		confirmationOutOfUse() {
			return this.outOfUseValue == true ? this.confirmationOutOfUseFalse : this.confirmationOutOfUseTrue;
		},
		categories() {
			return [this.machine.main_category, ...this.machine.categories];
		},
	},
	methods: {
		translateLocation,
		translateOpeningTime,
		...mapActions("machine", {
			setOutOfUse: "SET_OUT_OF_USE",
			fetchMachine: "FETCH_DATA_MACHINE",
		}),
		changeOutOfUse(value) {
			this.outOfUseValue = value;
			this.showEditModal = false;
			this.showConfirmationModal = true;
		},
		outOfUse(value) {
			const params = {
				id: this.machine.vending_machine_id,
				value: value,
			};
			this.setOutOfUse(params);
			this.showConfirmationModal = false;
			this.outOfUseValue = true;
		},
		closeConfirmationModal() {
			this.showConfirmationModal = false;
		},
		openEditModal() {
			this.showEditModal = true;
		},
		closeEditModal() {
			this.showEditModal = false;
		},
	},
	created() {
		this.fetchMachine(this.$route.params.id);
	},
};
